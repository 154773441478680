import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { MetaFunction } from "@remix-run/node";
import { Link } from "@remix-run/react";
import { Button } from "~/components/ui/button";
import {
  NavigationMenu,
  NavigationMenuLink,
  NavigationMenuList,
} from "~/components/ui/navigation-menu";
import { Separator } from "~/components/ui/separator";
import { Sheet, SheetContent, SheetTrigger } from "~/components/ui/sheet";

export const meta: MetaFunction = () => {
  return [
    {
      name: "google-site-verification",
      content: "-z7lxauZNIAboUgPDWVrpl09RxWNCcRPrgdGg3DIIIc",
    },
    { title: "Covid Chemistry" },
    {
      name: "description",
      content:
        "A dating site specifically tailored for people who still take Covid seriously.",
    },
  ];
};

export default function LandingPage() {
  return (
    <div className="bg-white">
      <header className="sticky inset-x-0 top-0 z-50 bg-background border-b border-border">
        <div
          // This will be the nav
          className="flex items-center justify-between p-6 lg:px-8"
          // aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <img src="/logo.png" alt="Cork" className="size-8 mr-4" />
            Covid Chemistry
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Button variant="link" asChild>
              <Link to="/policy/community-safety">Safety</Link>
            </Button>
            <Button variant="outline" asChild className="ml-6">
              <Link to="/login">Log in</Link>
            </Button>
          </div>
          <div className="lg:hidden">
            <Sheet>
              <SheetTrigger asChild>
                <Button variant="ghost">
                  <HamburgerMenuIcon className="size-6" />
                </Button>
              </SheetTrigger>
              <SheetContent>
                <NavigationMenu className="py-6 w-full max-w-full flex flex-col items-stretch">
                  <NavigationMenuList className="w-full items-stretch justify-normal flex-col gap-2 space-x-0">
                    <NavigationMenuLink
                      to="/policy/community-safety"
                      className="w-full"
                      linkClassName="w-full justify-start"
                    >
                      Safety
                    </NavigationMenuLink>
                    <Separator />
                    <NavigationMenuLink
                      to="/login"
                      className="w-full"
                      linkClassName="w-full justify-start"
                    >
                      Login
                    </NavigationMenuLink>
                  </NavigationMenuList>
                </NavigationMenu>
              </SheetContent>
            </Sheet>
          </div>
        </div>
      </header>

      <div className="relative isolate px-6 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-brand-300 to-brand-400 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-24 sm:py-24 lg:py-36">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Welcome to Covid Chemistry
            </div>
          </div>

          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Date more safely
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              A dating site specifically tailored for people who still take
              Covid seriously.
            </p>
            <p className="mt-4 text-gray-600 w-full">
              We created this passion project of a web app to help folks find
              others that believe in keeping each other safe and forming
              Covid-safer connections, particularly romantic ones.
            </p>

            <Button variant="outline" asChild className="mt-14">
              <Link to="/login">Get started</Link>
            </Button>
            {/* <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get started
              </a>
              <a
                href="#"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div> */}
          </div>
        </div>

        <footer className="py-4 mt-auto flex items-center flex-col gap-4">
          <div className="text-xs text-gray-600">
            Follow us on Twitter, we want to hear from you!
          </div>
          <a href="https://x.com/CovidChemistry">
            <img
              src="/x-logo.png"
              alt=""
              className="size-4 stroke-black fill-black"
            />
          </a>
          <div className="flex gap-3 text-xs text-brand-600">
            <a href="https://app.getterms.io/view/RWLMx/privacy/en-us">
              Privacy Policy
            </a>
            <a href="https://app.getterms.io/view/RWLMx/tos/en-us">
              Terms of Service
            </a>
            <a href="https://app.getterms.io/view/RWLMx/aup/en-us">
              Acceptable Use Policy
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
}
